<template>
    <div class="swiper-subject-component" @click.stop="stopClick">
        <div class="box">

            <div class="list">
                <ul>
                    <li class="p4 com-li">
                        <a href="#">
                            <img @click="goDetail(bannerData[3].id)" v-if="bannerData[3].logo2" :src="bannerData[3].logo2" alt="" />
                            <img v-else @click="goDetail(bannerData[3].id)"  src="/img/bg.png" alt="" />
                            <p>{{bannerData[3].name}}</p>
                        </a>
                    </li>
                    <li class="p3 com-li">
                        <a href="#">
                            <img v-if="bannerData[0].logo2" @click="goDetail(bannerData[0].id)"  :src="bannerData[0].logo2" alt="" />
                            <img v-else @click="goDetail(bannerData[0].id)"  src="/img/bg.png" alt="" />
                            <p>{{bannerData[0].name}}</p>
                        </a>
                    </li>
                    <li class="p2 com-li">
                        <a href="#">
                            <img v-if="bannerData[1].logo2" @click="goDetail(bannerData[1].id)"  :src="bannerData[1].logo2" alt="" />
                            <img v-else @click="goDetail(bannerData[1].id)"  src="/img/bg.png" alt="" />
                            <p>{{bannerData[1].name}}</p>
                        </a>
                    </li>
                    <li class="p1 com-li">
                        <a href="#">
                            <img v-if="bannerData[2].logo2" @click="goDetail(bannerData[2].id)"  :src="bannerData[2].logo2" alt="" />
                            <img v-else @click="goDetail(bannerData[2].id)"  src="/img/bg.png" alt="" />
                            <p>{{bannerData[2].name}}</p>
                        </a>
                    </li>
                </ul>
            </div>

            <a href="javascript:;" class="prev btn">
                <</a> <a href="javascript:;" class="next btn">>
            </a>

            <!-- <div class="buttons">
                <a href="javascript:;"><span class="blue"></span></a>
                <a href="javascript:;"><span></span></a>
                <a href="javascript:;"><span></span></a>
                <a href="javascript:;"><span></span></a>
                <a href="javascript:;"><span></span></a>
                <a href="javascript:;"><span></span></a>
                <a href="javascript:;"><span></span></a>
            </div> -->
        </div>
    </div>
</template>

<script>
var cArr = ['p4', 'p3', 'p2', 'p1']
var index = 0
var timer
// var $a = $('.buttons a')
// var $s = $('.buttons span')
export default {
    props: {
        bannerData: {
            type: Array,
            default: []
        }
    },
    data() {
        return {}
    },

    watch: {
        bannerData : {
            handler(newValue, oldValue) {
                //父组件param对象改变会触发此函数

                this.init()
            }
        }
    },
    mounted() {
        // this.init()
    },
    beforeDestroy () {
        clearInterval(timer)
        timer=null
    },
    methods: {
        goDetail (id) {
            this.$router.push({path: '/subjectDetail/s', query: {id: id, pN: encodeURI('专题库')}})
        },
        init() {
            
            var _this = this
            $('.next').click(function() {
                _this.nextimg()
            })

            $('.prev').click(function() {
                _this.previmg()
            })

            //通过底下按钮点击切换
            // $a.each(function() {
            //     $(this).click(function() {
            //         var myindex = $(this).index()
            //         var b = myindex - index
            //         if (b == 0) {
            //             return
            //         } else if (b > 0) {
            //             /*
            //              * splice(0,b)的意思是从索引0开始,取出数量为b的数组
            //              * 因为每次点击之后数组都被改变了,所以当前显示的这个照片的索引才是0
            //              * 所以取出从索引0到b的数组,就是从原本的这个照片到需要点击的照片的数组
            //              * 这时候原本的数组也将这部分数组进行移除了
            //              * 再把移除的数组添加的原本的数组的后面
            //              */
            //             var newarr = cArr.splice(0, b)
            //             cArr = $.merge(cArr, newarr)
            //             $('.list ul li').each(function(i, e) {
            //                 $(e)
            //                     .removeClass()
            //                     .addClass(cArr[i])
            //             })
            //             index = myindex
            //             // _this.show()
            //         } else if (b < 0) {
            //             /*
            //              * 因为b<0,所以取数组的时候是倒序来取的,也就是说我们可以先把数组的顺序颠倒一下
            //              * 而b现在是负值,所以取出索引0到-b即为需要取出的数组
            //              * 也就是从原本的照片到需要点击的照片的数组
            //              * 然后将原本的数组跟取出的数组进行拼接
            //              * 再次倒序,使原本的倒序变为正序
            //              */
            //             cArr.reverse()
            //             var oldarr = cArr.splice(0, -b)
            //             cArr = $.merge(cArr, oldarr)
            //             cArr.reverse()
            //             $('.com-li').each(function(i, e) {
            //                 $(e)
            //                     .removeClass()
            //                     .addClass(cArr[i])
            //             })
            //             index = myindex
            //             // _this.show()
            //         }
            //     })
            // })

            //			进入页面自动开始定时器
            timer = setInterval(_this.nextimg, 5000)

            //			鼠标移入box时清除定时器
            $('.box').mouseover(function() {
                clearInterval(timer)
                timer=null
            })

            //		鼠标移出box时开始定时器
            $('.box').mouseleave(function() {
                timer = setInterval(_this.nextimg, 5000)
            })
        },
        imgClick() {
            var _this = this
        },
        stopClick() {
            return
        },
        previmg() {
            //上一张
            cArr.unshift(cArr[3])
            cArr.pop()
            //i是元素的索引，从0开始
            //e为当前处理的元素
            //each循环，当前处理的元素移除所有的class，然后添加数组索引i的class
            $('.list ul li').each(function(i, e) {
                $(e)
                    .removeClass()
                    .addClass(cArr[i])
            })
            index--
            if (index < 0) {
                index = 3
            }
            // this.imgClick()
            // this.show()
        },
        nextimg() {
            //下一张
            cArr.push(cArr[0])
            cArr.shift()
            // console.log('cArr', cArr)
            $('.list ul li').each(function(i, e) {
                $(e)
                    .removeClass()
                    .addClass(cArr[i])
            })
            index++
            if (index > 3) {
                index = 0
            }
            // this.imgClick()
            // this.show()
        },
        show() {
            //改变底层按钮颜色
            $($s)
                .eq(index)
                .addClass('blue')
                .parent()
                .siblings()
                .children()
                .removeClass('blue')
        }
    }
}
</script>

<style lang="scss" scoped>
.swiper-subject-component {
    // height: 240px;
    .box {
        // margin-top: 300px;
        width: 100%;
        height: 240px;
        position: relative;
    }

    .list {
        width: 1200px;
        height: 240px;
        overflow: hidden;
        position: absolute;
        left: 50%;
        margin-left: -600px;
        // background: red;
        li a {
            img {
                background: rgba(0, 0, 0, 0.2);
            }
        }
    }

    .btn {
        position: absolute;
        left: 287px;
        top: 50%;
        width: 26px;
        height: 26px;
        line-height: 22px;
        font-size: 22px;
        color: white;
        text-decoration: none;
        text-align: center;
        background: #bd1a2d;
        opacity: 0.8;
        cursor: pointer;
        z-index: 100;
    }

    .next {
        left: auto;
        right: 271px;
    }

    li {
        position: absolute;
        top: 0;
        left: 0;
        list-style: none;
        opacity: 0;
        transition: all 0.3s ease-out;
        a {
            display: block;
            width: 662px;
            height: 240px;
            p {
                display: none;
            }
        }
    }

    img {
        width: 662px;
        height: 240px;
        border: none;
        float: left;
    }

    .p1 {
        transform: translate3d(-275px, 0, 0) scale(1);
        // transform: translate3d(-224px, 0, 0) scale(1);
    }

    .p2 {
        transform: translate3d(0px, 0, 0) scale(1);
        transform-origin: 0 50%;
        opacity: 0.8;
        z-index: 2;
    }

    .p3 {
        transform: translate3d(275px, 0, 0) scale(1);
        // transform: translate3d(224px, 0, 0) scale(1);
        z-index: 3;
        opacity: 1;
        border-left: 2px solid #fff;
        border-right: 2px solid #fff;

        a {
            position: relative;

            p {
                display: block;
                width: 662px;
                position: absolute;
                bottom: 0;
                left: 0;
                line-height: 46px;
                color: #fff;
                background: rgba(0, 0, 0, 0.5);
                font-size: 22px;

                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: center;
            }
        }
    }

    .p4 {
        transform: translate3d(550px, 0, 0) scale(1);
        // transform: translate3d(449px, 0, 0) scale(1);
        transform-origin: 100% 50%;
        opacity: 0.8;
        z-index: 2;
    }

    .p5 {
        transform: translate3d(825px, 0, 0) scale(1);
        // transform: translate3d(672px, 0, 0) scale(1);
    }

    .p6 {
        transform: translate3d(1100px, 0, 0) scale(1);
        // transform: translate3d(896px, 0, 0) scale(1);
    }

    .p7 {
        transform: translate3d(1375px, 0, 0) scale(1);
        // transform: translate3d(1120px, 0, 0) scale(1);
    }

    .buttons {
        position: absolute;
        width: 1200px;
        height: 30px;
        bottom: 0;
        left: 50%;
        margin-left: -600px;
        text-align: center;
        padding-top: 10px;
    }

    .buttons a {
        display: inline-block;
        width: 35px;
        height: 5px;
        padding-top: 4px;
        cursor: pointer;
    }

    span {
        display: block;
        width: 35px;
        height: 1px;
        background: red;
    }

    .blue {
        background: blue;
    }
}
</style>