<template>
    <div class="bread-component">
        <p style="margin-right: 6px;">
            <router-link to="/">首页 ></router-link>
        </p>
        <p v-if="pN" style="margin-right: 6px; cursor: pointer;" @click="goBack" v-html="pN+ '  >'">   </p>
        <p v-html="childName"></p>
    </div>
</template>

<script>
export default {
    name: '',
    props: {
        pN: {
            type: String,
            default: ''
        },
        childName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {}
    },
    methods: {
        goBack () {
            // if (this.$route.query.pN == '哲学社会科学成果开放获取平台' || this.$route.query.pN=='哲学社会科学成果预印本平台') {
            //     if (this.$route.query.pN == '哲学社会科学成果开放获取平台') {
            //         this.$router.replace({path: '/oa/openaccess'})
            //     } else {
            //         this.$router.replace({path: '/preprint/p'})
            //     }
            // }
            this.$router.back(-1)
            window.scrollTo(0, 0)
        }
    }
}
</script>

<style lang="scss" scoped>
.bread-component {
    display: flex;
    margin-top: 14px;
    margin-bottom: 12px;
    align-items: center;
    p {
        line-height: 22px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        color: #404040;
        a {
            display: block;
            font-size: 16px;
            line-height: 22px;
            font-family: Microsoft YaHei;
            color: #404040;
        }
    }
    p >>> .role4 {
        vertical-align: middle;
        width: 16px !important;
        height: 16px !important;
        margin-top: -2px;
    }
}
</style>
