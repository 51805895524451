<template>
    <div class="subject-page wid1200">
        <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
        <swiper-subject :bannerData="bannerData" v-if="!$route.query.id"></swiper-subject>
        <div class="top-wrap"></div>
        <div class="main-wrap">


            <div class="left-main-list">
                <ul>
                    <li v-for="item in listData" :key="item.id">
                        <div @click="goDetail(item.id)">
                            <img v-if="item.logo1" :src="item.logo1" alt="" />
                            <img v-else alt="" />
                        </div>
                        <div class="content">
                            <h5 @click="goDetail(item.id)">{{item.name}}</h5>
                            <p v-html="item.memo1">

                            </p>
                        </div>
                    </li>
                    <!-- <li>
                        <div>
                            <img src="../assets/img/test1.jpg" alt="" />
                        </div>
                        <div class="content">
                            <h5>约翰逊：英国将探寻各种途径以与欧盟达成贸易协议</h5>
                            <p>
                                中新网10月12日电 据路透社报道，当地时间10日，英国首相约翰逊对法国总统马克龙表示，
                                英国将探寻每一条能够与欧盟达成贸易协议的途径。但未来几天，双方仍需在弥合彼此之间
                                巨大落差方面，取得进展。 据报道，约翰逊的办公室就两人此次通话表示...
                            </p>
                        </div>
                    </li>
                    <li>
                        <div>
                            <img src="../assets/img/test1.jpg" alt="" />
                        </div>
                        <div class="content">
                            <h5>约翰逊：英国将探寻各种途径以与欧盟达成贸易协议</h5>
                            <p>
                                中新网10月12日电 据路透社报道，当地时间10日，英国首相约翰逊对法国总统马克龙表示，
                                英国将探寻每一条能够与欧盟达成贸易协议的途径。但未来几天，双方仍需在弥合彼此之间
                                巨大落差方面，取得进展。 据报道，约翰逊的办公室就两人此次通话表示...
                            </p>
                        </div>
                    </li> -->
                </ul>

            </div>
            <div class="right-main">
                <div class="right-download-wrap">
                    <div class="com-title">
                        <div>
                            <img class="title-img" src="../assets/img/download.png" alt="" />
                            <h5>下载排行</h5>
                        </div>
                    </div>
                    <ul class="right-download" style="padding-top: 20px;">
                        <!-- <li class="first-download">
                            <div>
                                <img src="../assets/img/book.png" alt="" />
                            </div>
                            <div>
                                <h5 class="right-title" v-if="hotList && hotList[0] && hotList[0].title">
                                    {{hotList[0].title}}
                                </h5>
                                <p>近代史学刊》是华中师范大学中国近代史研究...</p>
                            </div>
                        </li> -->
                        <li class="download-li" v-for="(item, index) in hotList" :key="index" :id="item.id" v-if="index<10" @click="goHot(item)" style="cursor: pointer;">
                            <!-- <img src="../assets/img/lock.png" alt="" /> -->
                            {{item.searchTitle? item.searchTitle : item.title}}
                        </li>
                        <!-- <li class="download-li">
                            <img src="../assets/img/lock.png" alt="" />珠海海洋生态文化建设思考
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SwiperSubject from '../components/SwiperSubject'
import BreadCrumb from '../components/BreadCrumb'

import img1 from '../assets/img/book.png'

export default {
    components: { BreadCrumb, SwiperSubject },
    data() {
        return {
            pN: '',
            pageName: '专题库',
            currentPage: 1,
            total: 0,
            bannerData: [{
                logo: img1,
                name: '',
                seoDescription: ''
            }, {
                logo: img1,
                name: '',
                seoDescription: ''
            }, {
                logo: img1,
                name: '',
                seoDescription: ''
            }, {
                logo: img1,
                name: '',
                seoDescription: ''
            }],
            listData: [],
            hotList: []
        }
    },
    methods: {
        handleCurrentChange (val) {
            // console.log('valval', val)
        },
        goHot (item) {
            this.$router.push({path: '/aD/a', query: {id: item.id, pN: encodeURI(this.pageName)}})
        },
        getHotList(){
            var _this  = this;
            _this.$http.get('/admin/api/article/hot')
                .then(res=>{
                    if(res.data.code == 0){
                        _this.hotList = res.data.data;
                    }
                })
        },
        goDetail (id) {
            this.$router.push({path: '/subjectDetail/s', query: {id: id, pN: encodeURI(this.pageName)}})
        },
        getData () {
            var _this =this
            this.$http({
                methods: 'get',
                url: '/admin/api/library/list',
                params: {
                    parentId: 2,
                    pageSize: 100,
                    pageNum: 1
                }
            }).then(res => {
                if (res.data.code == 0) {
                    _this.bannerData = res.data.data.rows.slice(0, 4)
                    _this.listData = res.data.data.rows.slice(4, 100)
                    // console.log(_this.bannerData, _this.listData)
                }
            })
        },
        getDetail() {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/library/detail?id=' + this.$route.query.id
            }).then(res => {
                if (res.data.code == 0) {
                    var data = res.data.data
                    // _this.pageName = res.data.data.name
                    // _this.libraryData = data

                    // _this.expertData = data.scholarList
                    // _this.insData = data.researchList
                    if (data.recommendSubjectList && data.recommendSubjectList.length>0) {
                        // _this.specialData = data.recommendSubjectList.splice(0, 4)
                        // _this.bannerData = data.recommendSubjectList.slice(0, 4)
                        _this.listData = data.recommendSubjectList
                    } else {
                        _this.bannerData = []
                        _this.listData = []
                    }
                    // console.log('专题_this.specialData', _this.specialData)
                }
            })
        }
    },

    created () {
        if (this.$route.query.pN) {
            this.pN = decodeURI(this.$route.query.pN)
        } else {
            this.pN = ''
        }
        if (this.$route.query.id) {
            //子库下专题
            this.getDetail()
        } else {
            this.getData()
        }

        this.getHotList()
    }
}
</script>

<style lang="scss" scoped>
.subject-page {
    .main-wrap {
        margin-top: 20px;
        // margin-top: 140px;
        display: flex;
        justify-content: space-between;
        .left-main-list {
            width: 860px;
            box-sizing: border-box;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            padding: 0 22px;
            margin-bottom: 40px;
            ul {
                li {
                    display: flex;
                    border-bottom: 1px solid #e6e6e6;
                    div:first-child {
                        img {
                            width: 230px;
                            height: 145px;
                            margin: 20px 19px 20px 0;
                            cursor: pointer;
                        }
                    }
                    div.content {
                        flex: 1;
                        h5 {
                            width: 565px;
                            margin-top: 20px;
                            font-size: 20px;
                            line-height: 50px;
                            cursor: pointer;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        p {
                            font-size: 14px;
                            color: #666;
                            line-height: 26px;
                            margin-top: 4px;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
                li:last-child {
                    border-bottom: none;
                }
            }
        }
        .right-main {
            width: 316px;
            box-sizing: border-box;
            // border: 1px solid #f5f5f5;
            // box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            .right-download-wrap {
                width: 316px;
                padding: 0 16px;
                box-sizing: border-box;
                border: 1px solid #f5f5f5;
                box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                .com-title {
                    height: 45px;
                    line-height: 45px;
                    border-bottom: 2px solid #dedede;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    div {
                        display: flex;
                        align-items: center;

                        img {
                            width: 24px;
                            height: 24px;
                            margin-right: 11px;
                            margin-left: 3px;
                        }
                        h5 {
                            height: 45px;
                            line-height: 45px;
                            font-size: 24px;
                            color: #000000;
                            border-bottom: 3px solid #bd1a2d;
                        }
                    }

                    ul {
                        display: flex;
                        li {
                            font-size: 18px;
                            margin-right: 18px;
                            cursor: pointer;
                            line-height: 36px;
                            color: #333;
                        }
                    }
                    span {
                        font-size: 14px;
                        color: #404040;
                        cursor: pointer;
                        img {
                            width: 4px;
                            height: 8px;
                            margin-left: 10px;
                        }
                    }
                }
                ul.right-download {
                    .first-download {
                        display: flex;
                        margin-top: 14px;
                        div {
                            img {
                                width: 118px;
                                height: 74px;
                                margin-right: 9px;
                                margin-bottom: 15px;
                            }
                            h5 {
                                width: 155px;
                                font-size: 16px;
                                color: #333333;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                margin-bottom: 10px;
                            }
                            p {
                                width: 155px;
                                font-size: 14px;
                                color: #666666;
                                text-overflow: -o-ellipsis-lastline;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                line-clamp: 2;
                                -webkit-box-orient: vertical;
                            }
                        }
                    }
                    .download-li {
                        width: 282px;
                        box-sizing: border-box;
                        line-height: 17px;
                        margin: 0 auto 18px;
                        font-size: 16px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        padding-left: 10px;
                        display: inline-block;
                        position: relative;
                        img {
                            width: 19px;
                            height: 19px;
                            margin-right: 4px;
                            vertical-align: middle;
                        }
                    }
                    .download-li::before {
                        content: '';
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background: #ffac13;
                        position: absolute;
                        left: 0;
                        top: 8px;
                    }
                }
            }
        }
    }
}
</style>
